import React, { useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AppRoutes from './Routes';
import './App.css';
import CookiesServices from './services/CookiesService';
import Sidebar from './layout/sidebar/SideBar';
import Header from './layout/header/Header';
import CircularProgress from '@mui/material/CircularProgress';
import './oneclick/configure/i18n'
import ReactGA from "react-ga4";

const App = () => {
    const [isSidebarVisible, setSidebarVisible] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const cookieService = useMemo(() => new CookiesServices(), []);
    const navigate = useNavigate();
    const location = useLocation();
    const [isSecured, setIsSecured] = useState(true);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

    var isPasswordResetPage = location.pathname === '/passwordReset';
    var isWelcome = location.pathname === '/qrpay';
    const isValidation = location.pathname === '/validation';
    const isPolicy = location.pathname === '/policy';
    const isOTPVerification = location.pathname === '/otp-verification';
    const isHelp = location.pathname === '/help';
    const isContact = location.pathname === '/contact';
    const isAbout = location.pathname === '/about';

    ReactGA.send({ hitType: "pageview", page: location.pathname });

    useEffect(() => {
        if (window.self === window.top) {
            setIsSecured(true); 
        } else {
            setIsSecured(false); 
        }

        const authenticateUser = async () => {
            const params = new URLSearchParams(window.location.search);
            var module = params.get('code');
            if(module === "qrpay"){
                isWelcome = true;
                navigate('/qrpay');
            } 
            if(module === "passwordreset"){
                isPasswordResetPage = true;
                navigate('/passwordReset');
            } 
            // Attempt to save login details from URL
            const loginSuccess = await cookieService.saveLoginDetailsFromUrl();

            if (loginSuccess) {
                setIsLoggedIn(true);
                // navigate('/dashboard');
            } else {
                if (isPasswordResetPage || isWelcome || isValidation || isPolicy || isOTPVerification || isHelp || isContact || isAbout) {
                    setIsLoggedIn(true);
                } else {
                    //Redirect to WSO2 for authentication if cookies are not valid
                    window.location.href = `${process.env.REACT_APP_LOGIN_URL}`;
                }
            }
        };
        authenticateUser();
    }, [cookieService, navigate, isSecured, isPasswordResetPage, isWelcome, isValidation, isPolicy, isOTPVerification, isHelp, isContact, isAbout]);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 768);
            setIsSmallScreen(isSmallScreen);
            setSidebarVisible(!isSmallScreen);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (!isSecured) {
        return <div className='security-msg'>If you see this page, Web App link you have clicked on is under Clickjacking security attack!</div>;
    }

    return (
        <div className="app">
            {!isLoggedIn ? (
                <div className="loading-overlay">
                <CircularProgress
                    size={60}
                    thickness={4.5}
                    sx={{
                        color: 'linear-gradient(to right, red, blue)',
                    }}
                />
            </div>
            ) : (
                <>
                    {(isPasswordResetPage || (!isWelcome && !isValidation && !isPolicy && !isOTPVerification && !isHelp && !isContact && !isAbout)) && (
                        <div className="content-wrapper">
                            {!isPasswordResetPage && isSidebarVisible && <Sidebar onToggleSidebar={() => setSidebarVisible(!isSidebarVisible)} />}
                            <div className={`main-content ${isSidebarVisible ? 'with-sidebar' : 'full-width'}`}>
                                    {!isPasswordResetPage && <Header
                                        onToggleSidebar={() => setSidebarVisible(!isSidebarVisible)}
                                        currentPath={location.pathname}
                                    />}
                                <AppRoutes isLoggedIn={isLoggedIn} />
                            </div>
                        </div>
                    )}
                    {isWelcome || isValidation || isPolicy || isOTPVerification || isHelp || isContact || isAbout ? (
                        <main className="app-content">
                            <div className="content-inner">
                                <AppRoutes isLoggedIn={isLoggedIn} />
                            </div>
                        </main>
                    ) : null}
                </>
            )}
        </div>
    );
};

export default App;
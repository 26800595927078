import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';


const DashboardMain = lazy(() => import('./pages/Dash/Dash'));
const NewBusiness = lazy(() => import('./pages/NewBusiness/NewBusiness'));
const Renewals = lazy(() => import('./pages/Renewals/Renewals'));
const PolicyDetails = lazy(() => import('./pages/Policy/Policy'));
const News = lazy(() => import('./pages/News/News'));
const Birthdays = lazy(() => import('./pages/Birthdays/Birthdays'));
const Insights = lazy(() => import('./pages/Insights/Insights'));
const AdvisorSummary = lazy(() => import('./pages/AdvisorSummary/AdvisorSummary'));
const SalesPlan = lazy(() => import('./pages/SalesPlan/SalesPlan'));
const PasswordReset = lazy(() => import('./pages/PasswordReset/PasswordReset'));
const LeadsManagement = lazy(() => import('./pages/LeadsManagement/LeadsManagement'));
const PolicyInquiry = lazy(() => import('./pages/PolicyDetails/policy-inquiry/PolicyInquiry'));
const Welcome = lazy(() => import('./oneclick/pages/welcome/Welcome'));
const Validation = lazy(() => import('./oneclick/pages/validation-page/ValidationPage'));
const Policy = lazy(() => import('./oneclick/pages/policy/Policy'));
const OTPVerification = lazy(() => import('./oneclick/pages/otp/Otp'));
const Help = lazy(() => import('./oneclick/pages/help/Help'));
const Contact = lazy(() => import('./oneclick/pages/contact/Contact'));
const About = lazy(() => import('./oneclick/pages/about/About'));

const ProtectedRoute = ({ isLoggedIn, children }) => {
  return isLoggedIn ? children : <Navigate to="/" replace />;
};

ProtectedRoute.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const AppRoutes = ({ isLoggedIn }) => {
  return (
    <Suspense
      fallback={
        <div className="loading-overlay">
          <CircularProgress color="secondary" />
        </div>
      }
    >
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <DashboardMain />
            </ProtectedRoute>
          }
        />
        <Route
          path="/newBusiness"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <NewBusiness />
            </ProtectedRoute>
          }
        />
        <Route
          path="/renewals"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <Renewals />
            </ProtectedRoute>
          }
        />
        <Route
          path="/policyDetails"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <PolicyDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/salesPlan"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <SalesPlan />
            </ProtectedRoute>
          }
        />
        <Route
          path="/news"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <News />
            </ProtectedRoute>
          }
        />
        <Route
          path="/birthdays"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <Birthdays />
            </ProtectedRoute>
          }
        />
        <Route
          path="/insights"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <Insights />
            </ProtectedRoute>
          }
        />
        <Route
          path="/advisorSummary"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <AdvisorSummary />
            </ProtectedRoute>
          }
        />
        <Route
          path="/passwordReset"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <PasswordReset />
            </ProtectedRoute>
          }
        />
        <Route
          path="/?code=passwordreset"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <PasswordReset />
            </ProtectedRoute>
          }
        />
        <Route
          path="/leadsManagement"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <LeadsManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/policyDetails/Inquiry"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <PolicyInquiry />
            </ProtectedRoute>
          }
        />
         <Route
          path="/qrpay"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <Welcome />
            </ProtectedRoute>
          }
        />
         <Route
          path="/validation"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <Validation />
            </ProtectedRoute>
          }
        />
         <Route
          path="/policy"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <Policy />
            </ProtectedRoute>
          }
        />
         <Route
          path="/otp-verification"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <OTPVerification />
            </ProtectedRoute>
          }
        />
         <Route
          path="/help"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <Help />
            </ProtectedRoute>
          }
        />
         <Route
          path="/contact"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <Contact />
            </ProtectedRoute>
          }
        />
         <Route
          path="/about"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <About />
            </ProtectedRoute>
          }
        />
        <Route
          path="/?code=qrpay"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <Welcome />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Suspense>
  );
};

AppRoutes.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

export default AppRoutes;
import React, { useState } from 'react';
import './search-popup.css';
import { MdCancel } from 'react-icons/md';
import TableContainer from '../../../Components/table/TableContainer';
import SearchBar from '../../../Components/search-bar/SearchBar';

function SearchPopup({ role, agents, onClose, onAgentSelect, onSubAgentSelect, roleType, feedback, categories }) {
  const [searchValue, setSearchValue] = useState('');

  const columnsForAgents = [
    { field: 'agentCode', headerName: 'Code' },
    { field: 'name', headerName: 'Name' },
  ];

  const columnsForCategories = [
    { field: 'code', headerName: 'Code' },
    { field: 'name', headerName: 'Name' },
  ];

  const rowsForAgents = agents.map((agent) => ({
    id: agent.id,
    agentCode: agent.agentCode,
    name: agent.name,
  }));

  const rowsForCategories = categories.map((category) => ({
    id: category.code,
    code: category.code,
    name: category.name,
  }));

  const handleRowClick = (row) => {
    if (roleType === 'role' && typeof onAgentSelect === 'function') {
      if (categories.length > 0) {
        onAgentSelect({ agentCode: row.code, name: row.name });
      } else {
        onAgentSelect(row);
      }
    } else if (roleType === 'subRole' && typeof onSubAgentSelect === 'function') {
      onSubAgentSelect(row, row.code); 
    }
    onClose();
  };

  // search function
  const onSearchHierarchyList = (e) => {
    setSearchValue(e.target.value);
  };

  // filter and sort rows based on search value
  const filteredAndSortedRowsForAgents = rowsForAgents
    .filter((agent) => agent.agentCode.toLowerCase().includes(searchValue.toLowerCase()) || agent.name.toLowerCase().includes(searchValue.toLowerCase()))
    .sort((a, b) => {
      if (a.agentCode.toLowerCase().includes(searchValue.toLowerCase())) return -1;
      if (b.agentCode.toLowerCase().includes(searchValue.toLowerCase())) return 1;
      if (a.name.toLowerCase().includes(searchValue.toLowerCase())) return -1;
      if (b.name.toLowerCase().includes(searchValue.toLowerCase())) return 1;
      return 0;
    });

  const filteredAndSortedRowsForCategories = rowsForCategories
    .filter((category) => category.code.toLowerCase().includes(searchValue.toLowerCase()) || category.name.toLowerCase().includes(searchValue.toLowerCase()))
    .sort((a, b) => {
      if (a.code.toLowerCase().includes(searchValue.toLowerCase())) return -1;
      if (b.code.toLowerCase().includes(searchValue.toLowerCase())) return 1;
      if (a.name.toLowerCase().includes(searchValue.toLowerCase())) return -1;
      if (b.name.toLowerCase().includes(searchValue.toLowerCase())) return 1;
      return 0;
    });

  return (
    <div className="search-popup">
      <div className="search-popup-content">
        <span className="popup-close" onClick={onClose}>
          <MdCancel color="#E30613" size={24} />
        </span>
        <div className="popup-body">
          <h3>Select Option from List</h3>
          <SearchBar searchValue={searchValue} isReadOnly={false} onChange={onSearchHierarchyList} />
          {roleType === 'role' ? (
            categories.length > 0 ? (
              <TableContainer
                columns={columnsForCategories}
                rows={filteredAndSortedRowsForCategories}
                initialSortingModel={[]}
                onRowClick={handleRowClick}
              />
            ) : (
              <p>{feedback?.message || 'No categories found.'}</p>
            )
          ) : roleType === 'subRole' ? (
            agents.length > 0 ? (
              <TableContainer
                columns={columnsForAgents}
                rows={filteredAndSortedRowsForAgents}
                initialSortingModel={[]}
                onRowClick={handleRowClick}
              />
            ) : (
              <p>{feedback?.message || 'No agents found.'}</p>
            )
          ) : (
            <p>No data available.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default SearchPopup;

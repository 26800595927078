import i18n from 'i18next';
import en from '../languages/en.json';
import si from '../languages/si.json';
import ta from '../languages/ta.json';

i18n.init({
  resources: {
    en: { translation: en },
    si: { translation: si },
    ta: { translation: ta },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

import React, { useState, useEffect, useMemo, useRef } from 'react';
import { FaBell } from 'react-icons/fa';
import { IoReorderThreeOutline } from "react-icons/io5";
import './Header.css';
import CookiesServices from '../../services/CookiesService';
import { FiSearch } from "react-icons/fi";
import SearchMenu from '../search-menu/SearchMenu';
import UserImg from '../../assets/user.jpg';
import '../../colors/colors.css';
import { IoIosArrowDropdown } from "react-icons/io";
import { FaRegUserCircle } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import { MdOutlineLockReset } from "react-icons/md";
import { BiLogOutCircle } from "react-icons/bi";
import EHubImage from '../../../src/assets/form_logo.png';

const Header = ({ onToggleSidebar, currentPath }) => {
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [branch, setBranch] = useState('');
  const cookiesServices = useMemo(() => new CookiesServices(), []);
  const [openSearchMenu, setOpenSearchMenu] = useState(false);
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const storedCode = cookiesServices.get('code');
    const storedName = cookiesServices.get('name');
    const storedBranch = cookiesServices.get('branch');
    if (storedCode && storedName && storedBranch) {
      setCode(storedCode);
      setName(storedName);
      setBranch(storedBranch);
    }
  }, [cookiesServices]);

  const handleLogout = async () => {
    const accessToken = cookiesServices.get('AccessToken');
    cookiesServices.remove('AccessToken');
    cookiesServices.remove('UserName');
    cookiesServices.remove('Level');
    cookiesServices.remove('code');
    cookiesServices.remove('session_state');
    cookiesServices.remove('name');
    cookiesServices.remove('branch');
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'AuthenticationToken': accessToken
        },
      });
    } catch (error) {
    }
    window.location.href = `${process.env.REACT_APP_LOGIN_URL}`;
  };

  const handleSearchMenu = () => {
    setOpenSearchMenu(!openSearchMenu);
  };

  const closeSearchMenu = () => {
    setOpenSearchMenu(false);
  };

  const formatPath = (path) => {
    if (!path) return '';
    const pathParts = path.replace(/^\/+/, '').split('/');
    return pathParts
      .map((part) =>
        part.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, (str) => str.toUpperCase())
      )
      .join(' ');
  };

  // menu closing function for when outside clciked
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
    <div className="header">
      <div className="toggle-section">
        <IoReorderThreeOutline className="toggle" onClick={onToggleSidebar} />
        <div className="header-path">{formatPath(currentPath)}</div>
        <img src={EHubImage} alt="EHub" className="eHub-header-image" />
      </div>
      <div className="header-actions">
        <button className="header-button" onClick={handleSearchMenu}>
          <FiSearch className="header-icon" />
        </button>
        <FaBell className="header-icon" />
        <div className="user-icon">
          <img src={UserImg} alt="User" className="user-image" />
          <IoIosArrowDropdown
              className="header-icon"
              onClick={() => setMenuOpen(!menuOpen)}
              style={{ cursor: 'pointer', marginRight: '10px' }}
            />
          <div className="user-info">
            <span className="code">{code || ''}</span>
            <span className="level">{branch || ''}</span>
            <span className="username">{name ? name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ') : ''}</span>
          </div>
        </div>
      </div>
      {menuOpen && (
          <div className="custom-menu" ref={menuRef}>
            <div className="menu-item" onClick={() => setMenuOpen(false)}>
              <FaRegUserCircle className="menu-icon" /> Profile
            </div>
            <div
              className="menu-item"
              onClick={() => {
                setMenuOpen(false);
                navigate('/passwordReset');
              }}
            >
              <MdOutlineLockReset className="menu-icon" /> Password Reset
            </div>
            <div className="divider"></div>
            <div className="menu-item" onClick={handleLogout}>
              <BiLogOutCircle className="menu-icon" /> Logout
            </div>
          </div>
        )}
    </div>
      {openSearchMenu && <SearchMenu onClose={closeSearchMenu} />}
    </>
  );
};

export default Header;
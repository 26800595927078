import React, { useState, useEffect } from 'react';
import './search-menu.css';
import Role from '../../Components/role-layer/Role';
import CookiesServices from '../../services/CookiesService';
import SearchPopup from './search-popup/SearchPopup';
import SearchBar from '../../Components/search-bar/SearchBar';
import DatePicker from '../../Components/date-picker/DatePicker';
import PrimaryButton from '../../Components/primary-button/PrimaryButton';
import { MdCancel } from 'react-icons/md';

const SearchMenu = ({ onClose }) => {
  const [selectedRole, setSelectedRole] = useState(null);
  const [agents, setAgents] = useState([]);
  const [feedback, setFeedback] = useState({});
  const [roles, setRoles] = useState([]);
  const [subroles, setSubroles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [searchValueForRole, setSearchValueForRole] = useState('');
  const [searchValueForSubrole, setSearchValueForSubrole] = useState('');
  const [roleType, setRoleType] = useState('');
  const [selectedCategoryCode, setSelectedCategoryCode] = useState(null);
  const [resetDates, setResetDates] = useState(false);
  const cookiesServices = new CookiesServices();
  const UserName = cookiesServices.get('UserName');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const accessToken = cookiesServices.get('AccessToken');

  useEffect(() => {
    const hierarchyData = cookiesServices.get('hierarchy');
    console.log('Hierarchy Data:', hierarchyData);

    try {
      if (hierarchyData) {
        const hierarchy = typeof hierarchyData === 'string' ? JSON.parse(hierarchyData) : hierarchyData;
        setRoles(extractRoles(hierarchy));
      }
    } catch (error) {
      console.error('Failed to parse hierarchy data:', error);
      setRoles([]);
    }
  }, []);

  useEffect(() => {
    if (resetDates) {
      setTimeout(() => setResetDates(false), 0);
    }
  }, [resetDates]);

  // display role list 
  const extractRoles = (level) => {
    const rolesArray = [];
    if (level.name && level.id) {
      rolesArray.push({ name: level.name, id: level.id });
    }
    level.levels?.forEach((subLevel) => {
      rolesArray.push(...extractRoles(subLevel));
    });
    return rolesArray;
  };

  // role selection
  const handleRoleSelect = async (role) => {
    setSelectedRole(role);
    setIsPopupVisible(true);
    setSubroles([]);
    setCategories([]);
    setRoleType('role');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/hierarchy/${role.id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'AuthenticationToken': accessToken,
          'UserName': UserName
        },
      });
      const data = await response.json();

      if (response.ok) {
        setSubroles(extractRoles(data.hierarchy));
        setCategories(data.categories || []);
      } else {
        setFeedback({ type: 'error', message: data.message || 'Data not found for this ID.' });
      }
    } catch (error) {
      console.error('Error fetching subroles:', error);
      setFeedback({ type: 'error', message: 'Error fetching subroles. Please try again.' });
    }
  };

  // set search values based on selected role
  const handleAgentSelect = (agent) => {
    setSearchValueForRole(`${agent.agentCode} - ${agent.name}`);
    setSelectedCategoryCode(agent.agentCode);
    setIsPopupVisible(false);
  };

  // get sub role list
  const handleSubroleSelect = async (subrole, selectedCategoryCode) => {
    if (!selectedCategoryCode) {
      console.error('Category code is required.');
      return;
    }

    setIsPopupVisible(true);
    setRoleType('subRole');

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/get_sub_levels/${subrole.id}/${selectedCategoryCode}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'AuthenticationToken': accessToken,
          'UserName': UserName,
        },
      });
      const data = await response.json();

      if (response.ok) {
        if (Array.isArray(data)) {
          if (data.length === 0) {
            console.log('No agents found in the response');
          }
          setAgents(data);
        } else {
          setFeedback({ type: 'error', message: 'No agents found.' });
        }
      } else if (response.status === 404) {
        setFeedback({
          type: 'error',
          message: 'No agents found for the provided Category and channe.'
        });
      } else {
        setFeedback({ type: 'error', message: 'An error occurred. Please try again.' });
      }
    } catch (error) {
      console.error('Error fetching agents:', error);
      setFeedback({ type: 'error', message: 'Error fetching agents. Please try again.' });
    }
  };

  // select sub role nad set value in search bar
  const handleSubAgentSelect = (subAgent) => {
    setSearchValueForSubrole(`${subAgent.agentCode} - ${subAgent.name}`);
    setIsPopupVisible(false);
  };

  // fetch function
  const handleFetch = () => {
    if (fromDate && toDate) {
      cookiesServices.set('from_date', fromDate, { path: '/' });
      cookiesServices.set('to_date', toDate, { path: '/' });
    } else {
      console.log('Invalid date');
    }
     
    const setCookies = (value) => {
      const cookieKeys = ['searchByAgent'];
      cookieKeys.forEach((key) => {
        cookiesServices.set(key, value, { path: '/' });
      });
    };

    if (roleType === 'role' && selectedCategoryCode) {
      setCookies(selectedCategoryCode);
      onClose();
    } else if (roleType === 'subRole' && searchValueForSubrole) {
      const selectedAgentCode = searchValueForSubrole.split(' - ')[0];
      setCookies(selectedAgentCode);
      onClose();
    } else {
      console.log('No role or subrole selected.');
    }
  };

  // reset function
  const handleReset = () => {
    setSelectedRole(null);
    setSearchValueForRole('');
    setSearchValueForSubrole('');
    setAgents([]);
    setSubroles([]);
    setCategories([]);
    setResetDates(true);
    setFromDate(null); 
    setToDate(null);
    cookiesServices.remove('from_date', { path: '/' });
    cookiesServices.remove('to_date', { path: '/' });
    cookiesServices.remove('searchByAgent', { path: '/' });
  };

  return (
    <div className="search-menu-container">
      <div className="overlay" onClick={onClose} />
      <div className="search-menu">
        <div className="search-menu-header">
          Sort Options
          <div className="close-button">
            <MdCancel color="#E30613" size={24} onClick={onClose} />
          </div>
        </div>
        <div className="content">
          {/* role display */}
          <Role roles={roles}
            title="Select by Role"
            onRoleSelect={handleRoleSelect}
            roleType="role"
            reset={resetDates} />
          {searchValueForRole && (
            <SearchBar searchValue={searchValueForRole} />
          )}

          {/* sub role display */}
          {subroles.length > 0 && (
            <Role
              roles={subroles}
              title="Select by Sub Role"
              onRoleSelect={(subrole) => handleSubroleSelect(subrole, selectedCategoryCode)}
              roleType="subRole"
              reset={resetDates}
            />
          )}
          {searchValueForSubrole && (
            <SearchBar searchValue={searchValueForSubrole} />
          )}
          <DatePicker reset={resetDates} onFromDateChange={setFromDate} onToDateChange={setToDate} />
        </div>
        <div className="search-buttons-inline-container">
          <PrimaryButton text="Fetch" onClick={handleFetch} />
          <PrimaryButton text="Reset" bgColor="#b3b3b3" onClick={handleReset} />
        </div>

        {/* display role/subrole selection popup  */}
        {isPopupVisible && (
          <SearchPopup
            role={selectedRole}
            agents={agents}
            onClose={() => setIsPopupVisible(false)}
            onAgentSelect={handleAgentSelect}
            onSubAgentSelect={handleSubAgentSelect}
            roleType={roleType}
            feedback={feedback}
            categories={categories}
          />
        )}
      </div>
    </div>
  );
};

export default SearchMenu;

import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaTachometerAlt, FaBriefcase, FaClipboardList, FaNewspaper, FaBirthdayCake, FaChartPie, FaUserFriends, FaFileContract, FaCog } from 'react-icons/fa';
import './SideBar.css';
import EHubImage from '../../../src/assets/app_logo.png';
import '../../colors/colors.css';
import { BiLineChart } from "react-icons/bi";
import { FaLessThan } from "react-icons/fa";

const menuItems = [
  { id: 'dash', label: 'Dashboard', icon: <FaTachometerAlt />, path: '/dashboard' },
  { id: 'newBusiness', label: 'New Business', icon: <FaBriefcase />, path: '/newBusiness' },
  { id: 'renewals', label: 'Renewals', icon: <FaFileContract />, path: '/renewals' },
  { id: 'policyDetails', label: 'Policy Details', icon: <FaClipboardList />, paths: ['/policyDetails', '/policyDetails/Inquiry']},
  // { id: 'salesPlan', label: 'Sales Plan', icon: <FaChartPie />, path: '/salesPlan' },
  // { id: 'leadsManagement', label: 'Leads Management', icon: <BiLineChart />, path: '/leadsManagement' },
  // { id: 'news', label: 'News', icon: <FaNewspaper />, path: '/news' },
  // { id: 'birthdays', label: 'Birthdays', icon: <FaBirthdayCake />, path: '/birthdays' },
  // { id: 'insights', label: 'Insights', icon: <FaCog />, path: '/insights' },
  // { id: 'advisorSummary', label: 'Advisor Summary', icon: <FaUserFriends />, path: '/advisorSummary' },
];

const Sidebar = ({onToggleSidebar}) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [clickedPath, setClickedPath] = useState('');
  const currentYear = new Date().getFullYear();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

  const handleClick = (path) => {
    setClickedPath(path);
    if (isSmallScreen) {
      onToggleSidebar();
    }
  };

    useEffect(() => {
      const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 768);
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

  return (
    <div className="sidebar">
      <div className="sidebar-content">
        <div className="eHub-image">
          <img src={EHubImage} alt="EHub" className="eHub-image" />
          {isSmallScreen ? <FaLessThan className="sidebar-collapse-icon" onClick={onToggleSidebar} /> : null}
        </div>
        <ul className="sidebar-list">
          {menuItems.map((item) => {
            const paths = item.paths || [item.path]; 
            const isSelected = paths.some(path => currentPath.startsWith(path)); // Check if current path starts with any of the paths

            return (
              <li key={item.id}>
                <Link
                  to={paths[0]} 
                  className={`sidebar-button ${isSelected ? 'selected' : ''} ${
                    clickedPath === paths[0] ? 'clicked' : ''
                  }`}
                  onClick={() => handleClick(paths[0])}
                >
                  {item.icon}
                  <span className={`sidebar-label ${isSelected ? 'selected' : ''}`}>
                    {item.label}
                  </span>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="version-main-wrapper">
        @ {currentYear} LOLC Life Assurance <br />
        Version {process.env.REACT_APP_VERSION} <br />
        All rights reserved
      </div>
      {/* Google Tag Manager Script */}
      <script
        dangerouslySetInnerHTML={{
          __html: `(function (w, d, s, l, i) {
                        w[l] = w[l] || [];
                        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
                        var f = d.getElementsByTagName(s)[0],
                                j = d.createElement(s),
                                dl = l != "dataLayer" ? "&l=" + l : "";
                        j.async = true;
                        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
                        f.parentNode.insertBefore(j, f);
                        })(window, document, "script", "dataLayer",  "${process.env.REACT_APP_GTM_ID}");`,
        }}
      />
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTM_ID}`}
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
                function gtag() {
                  dataLayer.push(arguments);
                }
                gtag("js", new Date());
                gtag("config", "${process.env.REACT_APP_GTM_ID}");`,
        }}
      />
    </div>
  );
};

export default Sidebar;
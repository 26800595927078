import { redirect } from "react-router-dom";
import Cookie from "universal-cookie";

class CookiesServices {
    constructor() {
        this.cookie = new Cookie(); 
    }

    get(key) {
        return this.cookie.get(key);
    }

    set(key, value, options) {
        this.cookie.set(key, value, options);
    }

    remove(key, options) {
        this.cookie.remove(key, options);
    }

    //Get details from URL and save them in cookie
    async saveLoginDetailsFromUrl() {
        var loginSuccess = false;        
        const params = new URLSearchParams(window.location.search);
        var code = params.get('code');
        var sessionState = params.get('session_state');

        if (!code || !sessionState) {
            sessionState = this.get('session_state');
            code = this.get('code');
            if (code && sessionState) {
                return true;
            }
        }
        
        if (code && sessionState) {
            try {
                const data = await this.loginUser({ userName: code, password: sessionState });
                console.log("saveLoginDetailsFromUrl");
                if (data.AccessToken && data.level) {
                    const expirationDate = new Date(Date.now() + 10 * 60 * 1000); // 10 minutes
                    this.set('AccessToken', data.AccessToken, { path: '/', expires: expirationDate });
                    this.set('Level', data.level, { path: '/', expires: expirationDate }); // Save agent_Rank
                    this.set('code', data.code, { path: '/', expires: expirationDate  }); // Save agent_code
                    this.set('UserName', data.code, { path: '/', expires: expirationDate  }); // Temp fix
                    this.set('name',data.name, { path: '/', expires: expirationDate }); // Save agent_name
                    this.set('branch',data.branch, { path: '/', expires: expirationDate }); // Save agent_branch_name
                    this.set('searchByAgent','', { path: '/', expires: expirationDate });
                    //Here is the URL data
                    this.set('session_state', sessionState, { path: '/' });
                    //this.set('UserName', code, { path: '/' }); // Save code as UserName
                    this.set('hierarchy', JSON.stringify(data.hierarchy), { path: '/' });
                    loginSuccess = true;
                } else {
                    this.remove('code',{ path: '/' });
                    this.remove('session_state', { path: '/' });
                }          
            //If there is login error remove cookie details
            } catch (error) {
                console.error('Login error:', error);
                this.remove('code',{ path: '/' });
                this.remove('session_state', { path: '/' });
            }
        }
        return loginSuccess;
    }

    async loginUser(credentials) {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/login/user`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(credentials),
        });

        if (!response.ok) {
            //Handle 401 error
            if (response.status === 401){
                console.error("401 error. Redirecting to login....");
                this.remove('code',{path:'/'});
                this.remove('session_state',{path:'/'});
                window.location.href = `${process.env.WINDOW_LOCATION_HREF}`; //Redirect to login
                return;
            }

            //Handle 500 Internal Server Error
            if (response.status === 500){
                alert( "Server error. Please try again later");
                return;
            }
               
            const errorData = await response.json();
            throw new Error(errorData.errorMessage || 'Failed to fetch user details');
        }

        return response.json();
    }
}



export default CookiesServices;

import React from 'react';
import './search-bar.css';

export default function SearchBar({ searchValue, isReadOnly = true, onChange }) {
  return (
    <div className="search-bar-container">
      <input
        type="text"
        className="search-input"
        value={searchValue}
        onChange={onChange}
        readOnly={isReadOnly}
      />
    </div>
  );
}
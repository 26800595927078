import React, { useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import './date-picker.css';

const { MonthPicker } = DatePicker;

export default function MonthYearPicker({ reset, onFromDateChange, onToDateChange }) {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  useEffect(() => {
    if (reset) {
      setFromDate(null);
      setToDate(null);
    }
  }, [reset]);

  const handleDateChange = (setter, callback) => (date, dateString) => {
    setter(date);
    if (date) {
      callback(dateString);
    } else {
      callback(null);
    }
  };

  return (
    <div className="date-picker">
      <div className="date-picker-field">
        <label htmlFor="from-date">From:</label>
        <MonthPicker
          id="from-date"
          value={fromDate ? dayjs(fromDate, 'YYYY-MM') : null}
          onChange={handleDateChange(setFromDate, onFromDateChange)}
          placeholder="YYYY-MM"
          format="YYYY-MM"
        />
      </div>

      <div className="date-picker-field">
        <label htmlFor="to-date">To:</label>
        <MonthPicker
          id="to-date"
          value={toDate ? dayjs(toDate, 'YYYY-MM') : null}
          onChange={handleDateChange(setToDate, onToDateChange)}
          placeholder="YYYY-MM"
          format="YYYY-MM"
        />
      </div>
    </div>
  );
}

MonthYearPicker.propTypes = {
  reset: PropTypes.bool,
  onFromDateChange: PropTypes.func.isRequired,
  onToDateChange: PropTypes.func.isRequired,
};

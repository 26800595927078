import React from 'react';
import './primary-button.css';

export default function PrimaryButton({ icon: Icon, text, bgColor = '#E30613', onClick, disabled = false, width }) {
  return (
    <button
      className={`primary-button ${disabled ? 'disabled' : ''}`}
      style={{ backgroundColor: disabled ? '#A9A9A9' : bgColor , width: width}}
      onClick={!disabled ? onClick : undefined}
      disabled={disabled}
    >
      {Icon && <Icon className="button-icon" />}
      {text}
    </button>
  );
}

import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbarColumnsButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import TablePagination from '@mui/material/TablePagination';
import useMediaQuery from '@mui/material/useMediaQuery';
import './table-container.css';
import {RiFullscreenFill} from 'react-icons/ri';
import {RiFullscreenExitLine} from 'react-icons/ri';

const CustomToolbar = ({ page, handlePageChange, rows, toggleFullscreen, isFullscreen, isSmallScreen  }) => (
  <div className="custom-toolbar">
    <div className="left-toolbar">
    {!isSmallScreen && (
        <>
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          <button
            className="fullscreen-toggle"
            onClick={toggleFullscreen}
            title={isFullscreen ? 'Exit Fullscreen' : 'Enter Fullscreen'} >
            {isFullscreen ? <RiFullscreenExitLine /> : <RiFullscreenFill />}
          </button>
        </>
      )}
    </div>
    <TablePagination
      component="div"
      count={rows.length}
      page={page}
      onPageChange={handlePageChange}
      rowsPerPage={100} 
      rowsPerPageOptions={[]} 
      sx={{
        '& .MuiToolbar-root': {
          minHeight: '10px',
          fontSize: '12px',
          fontFamily: 'Nunito Sans, sans-serif',
        },
        '&.MuiTablePagination-root': {
          overflow: 'hidden',
        }
      }}
    />
  </div>
);

const calculateColumnWidths = (columns, rows) =>
  columns.map((column) => {
    let maxLength = column.headerName?.length || 0;

    rows.forEach((row) => {
      const value = row[column.field]?.toString() || '';
      maxLength = Math.max(maxLength, value.length);
    });

    const calculatedWidth = Math.min(maxLength * 10 + 20);
    return {
      ...column,
      width: calculatedWidth,
    };
  });

export const renderPolicyTableRow = (label, value, isBold = false, isNoLabel = false) => (
  <div className="policy-table-row">
    {!isNoLabel && label && <span className={`policy-table-bold ${isBold ? 'policy-table-bold' : ''} policy-table-label`}>{label}</span>}
    <span>{value}</span>
  </div>
);

const TableContainer = ({ columns, rows, initialSortingModel, onRowClick = null }) => {
  const isSmallScreen = useMediaQuery('(max-width: 767px)');
  const [density, setDensity] = useState('standard');
  const [adjustedColumns, setAdjustedColumns] = useState([]);
  const [pageSize, setPageSize] = useState(100); 
  const [page, setPage] = useState(0);
  const [sortingModel, setSortingModel] = useState(initialSortingModel);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    setDensity('compact');
  }, []);

  useEffect(() => {
    const newColumns = calculateColumnWidths(columns, rows);
    setAdjustedColumns(newColumns);
  }, [columns, rows]);

  useEffect(() => {
    if (rows.length > 0 && !sortingModel.length) {
      setSortingModel(initialSortingModel); 
    }
  }, [rows, initialSortingModel, sortingModel]);
  
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const visibleRows = rows.filter(
    (_, index) => index >= page * pageSize && index < (page + 1) * pageSize
  );

  return (
    <div
      className={`table-wrapper ${isFullscreen ? 'fullscreen' : ''}`}
      style={{
        position: isFullscreen ? 'fixed' : 'relative',
        top: 0,
        left: 0,
        width: isFullscreen ? '100vw' : '100%',
        height: isFullscreen ? '100vh' : '100vh',
        background: '#fff',
        zIndex: isFullscreen ? 1300 : 'auto',
      }}
    >
      <DataGrid
        rows={visibleRows}
        columns={adjustedColumns}
        columnBuffer={5}
        hideFooterPagination={true}
        hideFooterSelectedRowCount={true}
        hideFooter={true}
        hideFooterRowCount={true}
        slots={{
          toolbar: (props) => <CustomToolbar {...props} page={page} handlePageChange={handlePageChange} rows={rows} toggleFullscreen={toggleFullscreen} isFullscreen={isFullscreen} isSmallScreen={isSmallScreen} />,
        }}
        density={density}
        onDensityChange={(newDensity) => setDensity(newDensity)}
        // pagination={false}
        components={{
          Footer: () => null,
        }}
        sortModel={sortingModel}
        onSortModelChange={(model) => setSortingModel(model)}
        sx={{
          width: '100%',
          maxHeight: isSmallScreen ? '80vh' : '60vh',
          overflowY: 'auto',
          '& .MuiDataGrid-scrollbar': {
            zIndex: 1,
          },
          '& .MuiDataGrid-cell': {
            fontSize: '12px',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            lineHeight: '1',
          },
          '& .MuiDataGrid-columnHeader': isSmallScreen
          ? { display: 'none' }  
          : {
              fontSize: '12px',
              position: 'sticky',
              top: 0,
              zIndex: 1000,
              backgroundColor: '#fff',
            },
          '& .MuiDataGrid-root': {
            overflowX: 'auto',
          },
          '& .MuiDataGrid-row': {
            cursor: onRowClick ? 'pointer' : 'default',
          },
        }}
        onRowClick={(params) => {
          if (onRowClick) {
            onRowClick(params.row);
          }
        }}
        getRowHeight={() => (isSmallScreen ? 'auto' : undefined)}
      />
    </div>
  );
};

export default TableContainer;
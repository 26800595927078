import React, { useState, useEffect } from 'react';
import './role.css';
import { FaUserTie, FaUser } from 'react-icons/fa'; 
import NationalImg from '../../assets/search-icons/srilanka_map.png';
import { FaFolderTree } from "react-icons/fa6";
import Province from '../../assets/search-icons/province_icon.png';
import Zone from '../../assets/search-icons/zone_icon.png';
import Region from '../../assets/search-icons/region_icon.png';
import Branch from '../../assets/search-icons/branch_icon.png';
import Cluster from '../../assets/search-icons/cluster_icon.png';
import Channel from '../../assets/search-icons/channel_icon.png';
import TM from '../../assets/search-icons/TM_icon.png'

const Role = ({ roles, title, onRoleSelect, roleType, reset }) => {
  const [selectedRole, setSelectedRole] = useState(null);

  const roleStyles = {
    National: { color: '#81D4FA', icon: <img src={NationalImg} alt="National" style={{ width: '26px', height: '26px' }} /> },
    Channel: { color: '#80DEEA', icon: <img src={Channel} alt="National" style={{ width: '24px', height: '24px' }} /> },
    'Sub Channel': { color: '#B2EBF2', icon:  <FaFolderTree /> },
    Cluster: { color: '#C8E6C9', icon: <img src={Cluster} alt="National" style={{ width: '24px', height: '24px' }} /> },
    Province: { color: '#A5D6A7', icon: <img src={Province} alt="National" style={{ width: '24px', height: '24px' }} /> },
    Zone: { color: '#C5E1A5', icon: <img src={Zone} alt="National" style={{ width: '24px', height: '24px' }} /> },
    Region: { color: '#9FA8DA', icon: <img src={Region} alt="National" style={{ width: '24px', height: '24px' }} /> },
    Branch: { color: '#D1C4E9', icon: <img src={Branch} alt="National" style={{ width: '24px', height: '24px' }} /> },
    FD: { color: '#80CBC4', icon: <FaUserTie /> },
    TM: { color: '#CE93D8', icon: <img src={TM} alt="National" style={{ width: '24px', height: '24px' }} /> },
    RSM: { color: '#A5B8E3', icon: <FaUser /> },
    ARSM: { color: '#9FA8DA', icon: <FaUser /> },
    TL: { color: '#81D4FA', icon: <FaUser /> },
    FP: { color: '#4DD0E1', icon: <FaUser /> },
    OC: { color: '#80DEEA', icon: <FaUser /> },
  };

  useEffect(() => {
    if (reset) {
      setSelectedRole(null);
    }
  }, [reset]);

  const handleRoleClick = (role) => {
    setSelectedRole(role);
    onRoleSelect(role);
  };

  return (
    <div className="role-container">
      <div className="role-title">{title}</div>
      <div className="roles">
        {roles.map((role, index) => {
          const { color, icon } = roleStyles[role.name] || { color: '#d9d9d9', icon: <FaUser /> }; 
          return (
            <div
              key={index}
              className={`role-item ${selectedRole === role ? 'active' : ''}`}
              onClick={() => handleRoleClick(role)}
            >
              <div className="role-icon" style={{ backgroundColor: color, fontSize: '24px' }}>
                {icon}
              </div>
              <p className="role-name">{role.name}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Role;
